.App {
    font-family: Tahoma, sans-serif;
}

.top-section {
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("./assets/images/clement-remond-8KP8lz4NTxY-unsplash.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.grid-section-2, .grid-section-3 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.grid-section-2 > div {
    flex: 49%;
    max-width: 49%;
}

.grid-section-3 > div {
    flex: 32%;
    max-width: 32%;
}

.content {
    margin: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.hero {
    background-color: transparent;
    padding: 20px;
    text-align: center;
    color: white;
}

.company-image > img {
    width: 480px;
    height: 480px;
    overflow: hidden;
}

.basic-info-section {
    width: 100%;
    margin-bottom: 50px;
}

.basic-info-column {
    font-size: 23px;
    display: flex;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.sales-section > div {
    min-height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
}

.sales-section > div > .sales-title {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    padding: 25px 0;
    font-weight: bold;
}

.sales-section > div > .sales-content {
    font-size: 28px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
}

.sales-section > div > .sales-content > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 15px;
}

.sales-section > .sales-tires  {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./assets/images/jahongir-ismoilov-AffCPyYCZUQ-unsplash.jpg");
}

.sales-section > .sales-gardening  {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./assets/images/markus-spiske-sFydXGrt5OA-unsplash.jpg");
}

.sales-section > .sales-misc  {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("./assets/images/erik-mclean-aielvGxZB0g-unsplash.jpg");
}

.separator {
    height: 25px;
}

a {
    color: lightgreen;
}

li {
    margin-bottom: 5px;
}

footer {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .company-image > img {
        width: 280px;
        height: 280px;
    }

    .grid-section-2 > div {
        flex: 100%;
        max-width: 100%;
    }

    .grid-section-3 > div {
        flex: 100%;
        max-width: 100%;
    }

    .sales-content {
        font-size: 15px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .company-image > img {
        width: 380px;
        height: 380px;
    }

    .grid-section-2 > div {
        flex: 100%;
        max-width: 100%;
    }

    .grid-section-3 > div {
        flex: 100%;
        max-width: 100%;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .company-image > img {
        width: 380px;
        height: 380px;
    }

    .grid-section-2 > div {
        flex: 100%;
        max-width: 100%;
    }

    .grid-section-3 > div {
        flex: 100%;
        max-width: 100%;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .company-image > img {
        width: 400px;
        height: 400px;
    }

    .grid-section-2 > div {
        flex: 100%;
        max-width: 100%;
    }

    .grid-section-3 > div {
        flex: 100%;
        max-width: 100%;
    }
}

.list-row {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

    .list-amount-content {
        font-size: 15px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .grid-section-2 > div {
        flex: 49%;
        max-width: 49%;
    }

    .grid-section-3 > div {
        flex: 32%;
        max-width: 32%;
    }

    .company-image > img {
        width: 480px;
        height: 480px;
    }
}
